@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ag-navbar-heading {
  display: flex;
  align-items: center;
}

.ag-navbar-heading b {
  font-size: 18px;
}

.ag-navbar-image {
  height: 66px;
  margin-left: calc(2vw - 14px);
  margin-right: 16px;
}
